import React from 'react'
import { MatrixRain } from '../../components/MatrixRain/MatrixRain'
import stules from './MainPage.module.css'
import { Link } from 'react-router-dom'
import { staticLinks } from '../../config/routingsLinks'

const MainPage = () => {
  return (
    <div className={stules.wrapper}>
    <Link to={staticLinks.planet} className={stules.link}>Matrix</Link>
    <MatrixRain></MatrixRain>
    </div>
  )
}

export default MainPage