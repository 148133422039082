import { staticLinks } from "../config/routingsLinks";
import MainPage from "../pages/MainPage/MainPage";
import MailPage from "../pages/MailPage/MailPage";
import PlanetPage from "../pages/PlanetPage/PlanetPage";
import GhostSchool from "../pages/GhostSchool/GhostSchool";
import Price from "../pages/PlanetPage/Price/Price";


export const publicRoutes = [
  {
    path: staticLinks.main,
    element: <MainPage></MainPage>,
  },
  {
    path: staticLinks.mail,
    element: <MailPage></MailPage>,
  },
  {
    path: staticLinks.planet,
    element: <PlanetPage></PlanetPage>,
  },
  {
    path: staticLinks.ghost,
    element: <GhostSchool></GhostSchool>,
  },
  {
    path: staticLinks.price,
    element: <Price></Price>,
  },
];
