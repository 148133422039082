import React from 'react'
import { MatrixRain } from '../../components/MatrixRain/MatrixRain'

const MailPage = () => {
  return (
    <div>
    <div>MailPage</div>
    {/* <MatrixRain></MatrixRain> */}
    </div>
  )
}

export default MailPage