import React from 'react'

import styles from './GhostSchool.module.css'
import { MatrixRain } from '../../components/MatrixRain/MatrixRain'
import { Link } from 'react-router-dom';

const GhostSchool = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div>
          <textarea className={styles.text}>
            Сюда проект Следы Эпох. Выведи его в некст реакт на другую страницу.
            Сегодня 11.10.2024 года в 32 школе города Орска старшеклассники
            утверждали следы нашей эпохи.
          </textarea>
        </div>
        <div>
          <textarea className={styles.text}>Сюда что то другое.</textarea>
        </div>
        <MatrixRain></MatrixRain>
      </div>
    </>
  );
}

export default GhostSchool