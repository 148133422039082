import React from 'react'

import styles from './Price.module.css'
import { MatrixRain } from '../../../components/MatrixRain/MatrixRain';

const Price = () => {
  return (
    <div className={styles.wrapper}>
      <center><p>
        Здравствуйте. На данном этапе мы только формируем всё и согласовываем
        все составляющие. Если вы хотите продать свою акцию, напишите на адрес
        этой почты <a href="https://tapa-peach.vercel.app/"> imperiya66.ru@gmail.com </a>
        вам заплатят за возврат акции. Ваш скан акции должен быть один в один с
        нашим сканом вашей акции. Второй момент. Вы можете проявить терпение
        подождав, когда акция тапы вырастит в цене, а после её продать. Есть
        третий способ. Вы можете как владелец тапы принимать участие в жизни
        нашей формирующийся компании. По всем вопросам напишите по адресу выше.
      </p></center>
      <MatrixRain></MatrixRain>
    </div>
  );
}

export default Price