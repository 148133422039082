export const staticLinks = {
  main: "/",
  mail: "/mail",
  planet: "/planet",
  profile: "/profile",
  ghost: "/ghost",
  price: "/price",
};

export const dynamicLinks = {
  commandment: (id) => `/commandment/${id}`,
};
